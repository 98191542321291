import { Message, UserData } from "@/app/data";
import { cn, getCurrentTime } from "@/lib/utils";
import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ChatBottombar from "./ChatBottombar";
import Eden from "../Eden/Eden";
import { v4 as uuidv4 } from "uuid";
import { ThreeDots } from "react-loader-spinner";
import ChatForm from "./ChatForm";
import Link from "next/link";
interface ChatListProps {
  messages?: Message[];
  // selectedUser: UserData;
  setStep: (step: number) => void;
  sendMessage: (newMessage: Message) => void;
  handleQuestionClick: (clickedMessageIndex: any) => void;
  loading: boolean;
  setShowForm: (showForm: boolean) => void;
  setLoading: (loading: boolean) => void;
  isMobile: boolean;
  showForm: boolean;
  sendAfterForm: (values: any) => void;
  removeForm: () => void;
  onOpenChange: (open: boolean) => void;
  submittedTime: string;
}

export function ChatList({
  messages,
  sendMessage,
  submittedTime,
  handleQuestionClick,
  isMobile,
  loading,
  showForm,
  setShowForm,
  setLoading,
  removeForm,
  sendAfterForm,
  onOpenChange,
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const [startedTime] = useState(getCurrentTime());
  const questionVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
      transformOrigin: "top left",
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: 0.5,
      },
    },
  };
  let previousMessage: any = null;
  return (
    <div className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col">
      <div
        ref={messagesContainerRef}
        className="w-full no-scrollbar overflow-y-auto overflow-x-hidden h-full flex flex-col"
      >
        <motion.div
          variants={questionVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={cn(
            "flex flex-col gap-2 pt-4 whitespace-pre-wrap items-start"
          )}
        >
          <div className="flex gap-2 items-center">
            <div className="w-[54px]  sm:w-[75px] aspect-square">
              <Eden className="!gap-[0.16rem] sm:!gap-[0.3rem]" />
            </div>
            <span className="text-[22px] sm:text-h4 font-semibold bg-accent py-3 px-6 rounded-md max-w-xs">
              Hi there!
            </span>
          </div>
        </motion.div>
        <motion.div
          variants={questionVariants}
          initial="hidden"
          animate={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              duration: 0.8,
              delay: 1,
            },
          }}
          exit="hidden"
          className={cn(
            "flex flex-col gap-2 py-2 whitespace-pre-wrap items-start"
          )}
        >
          <div className="flex gap-2 items-center">
            <span className="text-[22px] sm:text-h4 font-semibold bg-accent py-3 px-6 rounded-md max-w-[632px]">
              How can I help you today?
            </span>
          </div>
          <span className="text-white/50 text-sm font-medium">
            {startedTime && startedTime}
          </span>
        </motion.div>

        <AnimatePresence mode="popLayout">
          {messages?.map((message, index) => {
            if (index > 0) {
              //console.log("Previous message:", previousMessage);
            }
            message.replyTime = getCurrentTime();
            const currentMessage = message;

            // Update previousMessage after logging
            const previousMessageBeforeUpdate = previousMessage;
            // console.log(
            //   "Previous message before update:",
            //   previousMessageBeforeUpdate
            // );
            previousMessage = currentMessage;
            if (message.message === "showForm") {
              return (
                <ChatForm
                  messages={messages}
                  key={message.id}
                  removeForm={removeForm}
                  sendMessage={sendMessage}
                  messageIndex={index}
                  sendAfterForm={sendAfterForm}
                />
              );
            }
            if (message.message === "showLinks") {
              const linkList = [
                {
                  id: uuidv4(),
                  title: "View cleaning products",
                  link: "/products",
                  isUser: true,
                },
                {
                  id: uuidv4(),
                  title: "Access helpful cleaning guides ",
                  link: "/products",
                  isUser: true,
                },
                {
                  id: uuidv4(),
                  title: "Learn about reaching ESG goals",
                  link: "/products",
                  isUser: true,
                },
              ];
              return (
                <>
                  {linkList.map((link) => {
                    return (
                      <motion.div
                        key={message.id}
                        layout
                        initial={{ opacity: 0, scale: 1, x: 0 }}
                        animate={{ opacity: 1, scale: 1, x: 0 }}
                        exit={{
                          opacity: 0,
                          x: 0,
                        }}
                        transition={{
                          duration: 1,
                          // delay:
                          //   index < 3 && message.questionId === "q1" ? index * 0.3 : 0.3,
                          staggerChildren: 0.5,
                          type: "spring",
                        }}
                        className={cn(
                          "flex relative flex-col gap-2 py-2 group  whitespace-pre-wrap",
                          link.isUser ? "items-end " : "items-start"
                        )}
                      >
                        <Link
                          href={link.link}
                          className="flex gap-3 items-center"
                          onClick={() => onOpenChange(false)}
                        >
                          <span
                            className={cn(
                              "text-h4   transition-all font-semibold bg-accent p-3 rounded-md max-w-[632px]",
                              link.isUser
                                ? "bg-[#ECFEEE] hover:bg-main-accent cursor-pointer"
                                : ""
                            )}
                          >
                            {link.title}
                          </span>
                        </Link>
                      </motion.div>
                    );
                  })}
                </>
              );
            }
            return (
              <motion.div
                key={message.id}
                layout
                initial={{ opacity: 0, scale: 1, x: 0 }}
                animate={{ opacity: 1, scale: 1, x: 0 }}
                // exit={{ opacity: 0, x: 0 }}
                transition={{
                  duration: 1,
                  // delay:
                  //   index < 3 && message.questionId === "q1" ? index * 0.3 : 0.3,
                  staggerChildren: 0.5,
                  type: "spring",
                }}
                className={cn(
                  "flex relative flex-col gap-2 py-1 group  whitespace-pre-wrap",
                  message.isUser ? "items-end " : "items-start"
                )}
              >
                <div className="flex gap-3 items-center">
                  <span
                    className={cn(
                      "text-[22px] sm:text-h4   transition-all font-semibold bg-accent py-3 px-6 rounded-md max-w-[632px]",
                      message.isUser
                        ? "bg-[#ECFEEE] hover:bg-[#B2EDD0] border-2 border-transparent hover:border-main-accent  cursor-pointer"
                        : "",
                      message.isSelected ? "bg-main-accent cursor-default" : ""
                    )}
                    onClick={() => {
                      if (!message.isUser || message.isSelected) return;
                      handleQuestionClick(message.id); // Pass message id instead of index
                    }}
                  >
                    {message.message}
                  </span>
                </div>

                {message.isSelected && message?.time && (
                  <>
                    <span className="text-white/50 text-sm">
                      {message?.time}
                    </span>
                  </>
                )}
                {previousMessageBeforeUpdate &&
                  !previousMessageBeforeUpdate.isUser &&
                  !currentMessage.isUser && (
                    <span className="text-white/50 text-sm">
                      {message.replyTime && message.replyTime}
                    </span>
                  )}
                {message.message.startsWith("Company Name:") && (
                  <span className="text-white/50 text-sm">{submittedTime}</span>
                )}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 0 }}
          >
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#ffffff"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </motion.div>
        )}
      </AnimatePresence>
      {/* <ChatBottombar sendMessage={sendMessage} isMobile={isMobile} /> */}
    </div>
  );
}
