import { useState, useEffect } from "react";
import { DialogClose } from "../ui/dialog";
import { Button } from "../ui/button";
import { IoClose } from "react-icons/io5";
import { IoReload } from "react-icons/io5";
import Link from "next/link";
import Image from "next/image";
import { ChatList } from "../molecules/Chat/ChatList";
import { v4 as uuidv4 } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { trialReply, inquiryReply, distributorReply } from "@/app/data";
import { getCurrentTime } from "@/lib/utils";
type Props = {
  onOpenChange: (open: boolean) => void;
  isPage?: boolean;
};

const SmartForm = (props: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userPath, setUserPath] = useState("");
  const [messageState, setMessages]: any = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [submittedTime, setSubmittedTime] = useState("");
  const sendMessage = (newMessage: any) => {
    setMessages([...messageState, newMessage]);
  };
  const initialQuestion = [
    {
      id: 1,
      qid: 1,
      isUser: true,
      isSelected: false,
      path: "Trial products",
      message: "I want to trial your products",
      time: null,
    },
    {
      id: 2,
      qid: 1,
      isUser: true,
      isSelected: false,
      path: "General Enquiry",
      message: "I have a general enquiry",
      time: null,
    },
    {
      id: 3,
      qid: 1,
      isUser: true,
      isSelected: false,
      path: "Distributor",
      message: "I’m looking for/am a distributor",
      time: null,
    },
  ];

  const removeForm = () => {
    const updatedItems = messageState.filter(
      (item: any) => item.message !== "showForm"
    );
    setMessages(updatedItems);
  };
  const sendAfterForm = (values: any) => {
    let newMessageList = [
      {
        id: uuidv4(),
        isUser: true,
        isSelected: true,
        path: "Trial products",
        message: "Name: " + values.name,
      },
      {
        id: uuidv4(),
        isUser: true,
        isSelected: true,
        path: "Trial products",
        message: "Email: " + values.email,
      },
      {
        id: uuidv4(),
        isUser: true,
        isSelected: true,
        path: "Trial products",
        message: "Phone: " + values.phone,
      },
      {
        id: uuidv4(),
        isUser: true,
        isSelected: true,
        path: "Trial products",
        message: "Company Name: " + values.company,
      },
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        path: "Trial products",
        message: "Thank you!",
      },
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        path: "Trial products",
        message: "I’ll be in touch, look out for an email.",
      },
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        path: "Trial products",
        message: "Continue exploring...",
      },
    ];
    newMessageList.forEach((message, index) => {
      if (!message.isUser) {
        return setTimeout(() => {
          setMessages((prevMessages: any) => [...prevMessages, message]);
        }, index * 500); // Change 1000 to your desired delay in milliseconds
      }
      return setMessages((prevMessages: any) => [...prevMessages, message]);
    });
    setSubmittedTime(getCurrentTime());
    setTimeout(() => {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        {
          id: uuidv4(),
          isUser: false,
          isSelected: false,
          path: "Trial products",
          message: "showLinks",
        },
      ]);
    }, newMessageList.length * 500);
  };

  const resetHandler = () => {
    setMessages([]);
    setUserPath("");
    setStep(0);
    // setTimeout(() => {
    //   setMessages(initialQuestion);
    // }, 1000);
    const delay = 500; // 0.5 seconds
    let timeoutId: any;
    const sendMessages = () => {
      initialQuestion.forEach((message, index) => {
        timeoutId = setTimeout(() => {
          setMessages((prevMessages: any) => [...prevMessages, message]);
        }, index * delay);
      });
    };

    timeoutId = setTimeout(sendMessages, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  const handleQuestionClick = (messageId: string) => {
    const clickedMessage = messageState.find(
      (message: any) => message.id === messageId
    );
    if (!clickedMessage) return;

    const updatedMessages = messageState
      .filter((message: any) => {
        return message.qid !== clickedMessage.qid || message.id === messageId;
      })
      .map((message: any) => {
        if (message.id === messageId) {
          return {
            ...message,
            time: getCurrentTime(),
            isSelected: true,
          };
        } else {
          return {
            ...message,
          };
        }
      });

    let replyArray: any[] = [];

    switch (clickedMessage.path) {
      case "Trial products":
        if (clickedMessage.qid === 1) {
          replyArray = [...trialReply[0].reply, ...trialReply[0].options];
        }
        if (clickedMessage.qid === 3) {
          replyArray = [
            ...trialReply[0].options[0].reply,
            ...trialReply[0].options[0].options,
          ];
        }
        if (clickedMessage.qid === 4) {
          replyArray = [
            ...trialReply[0].options[0].options[0].reply,
            ...trialReply[0].options[0].options[0].options,
          ];
        }
        if (clickedMessage.qid === 5) {
          replyArray = [
            ...trialReply[0].options[0].options[0].options[0].reply,
            ...trialReply[0].options[0].options[0].options[0].options,
          ];
          setShowForm(true);
        }
        break;
      case "General Enquiry":
        if (clickedMessage.qid === 1) {
          replyArray = [...inquiryReply[0].reply, ...inquiryReply[0].options];
        }
        if (clickedMessage.qid === 3) {
          replyArray = [
            ...inquiryReply[0].options[0].reply,
            ...inquiryReply[0].options[0].options,
          ];
        }
        if (clickedMessage.qid === 4) {
          replyArray = [
            ...inquiryReply[0].options[0].options[0].reply,
            ...inquiryReply[0].options[0].options[0].options,
          ];
        }
        // if (clickedMessage.qid === 5) {
        //   replyArray = [
        //     ...inquiryReply[0].options[0].options[0].options[0].reply,
        //     ...inquiryReply[0].options[0].options[0].options[0].options,
        //   ];
        //   setShowForm(true);
        // }
        break;
      case "Distributor":
        if (clickedMessage.qid === 1) {
          replyArray = [
            ...distributorReply[0].reply,
            ...distributorReply[0].options,
          ];
        }
        if (clickedMessage.qid === 3) {
          replyArray = [
            ...distributorReply[0].options[0].reply,
            ...distributorReply[0].options[0].options,
          ];
        }
        if (clickedMessage.qid === 4) {
          replyArray = [
            ...distributorReply[0].options[0].options[0].reply,
            ...distributorReply[0].options[0].options[0].options,
          ];
        }
        break;
      default:
        replyArray = [];
        break;
    }
    let delayedMessages: any[] = [];
    replyArray.forEach((reply, index) => {
      if (reply.path === "Trial products" && reply.qid === 3) {
        return setTimeout(() => {
          setTimeout(() => {
            delayedMessages.push(reply);
            setMessages([...updatedMessages, ...delayedMessages]);
          }, index * 400);
        }, 500);
      }
      return setTimeout(() => {
        delayedMessages.push(reply);
        setMessages([...updatedMessages, ...delayedMessages]);
      }, index * 500); // Change 1000 to your desired delay in milliseconds
    });

    setUserPath(clickedMessage.path);
    setStep(step + 1);
  };
  useEffect(() => {
    const delay = 500; // 0.5 seconds
    let timeoutId: any;
    const sendMessages = () => {
      initialQuestion.forEach((message, index) => {
        timeoutId = setTimeout(() => {
          setMessages((prevMessages: any) => [...prevMessages, message]);
        }, index * delay);
      });
    };
    timeoutId = setTimeout(sendMessages, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  // useEffect(() => {
  //   console.log(messageState);
  // }, [messageState]);
  return (
    <section className="xl:container">
      <div className="flex justify-between mb-8">
        <Link className="flex-1 flex items-center justify-center" href={"/"}>
          <Image
            src={"/enviroplus-logo-dark.svg"}
            width={191}
            height={37}
            alt="Enviroplus logo"
            className="cursor-pointer transition-all mr-auto"
          />
        </Link>

        <div className="flex-1 hidden sm:flex justify-center container">
          <AnimatePresence>
            {userPath.length > 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Button
                  variant={"primary"}
                  className="flex gap-4 bg-[#0D3739] hover:bg-[#0D3739] hover:text-white/60 border-none text-white/60 font-semibold"
                  onClick={() => setStep(0)}
                >
                  {userPath}
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="flex flex-1 xs:gap-2 ml-2 sm:gap-4">
          <div className="ml-auto">
            <Button
              className="bg-transparent text-base border-2 border-transparent hover:bg-main-accent hover:text-black flex gap-3 text-white font-semibold"
              onClick={() => resetHandler()}
            >
              Reset <IoReload />
            </Button>
          </div>
          {/* <DialogClose asChild> */}
          {!props.isPage && (
            <div>
              <Button
                variant={"secondary-light"}
                onClick={() => props.onOpenChange(false)}
                className="flex gap-3"
              >
                Close <IoClose fontSize={24} />
              </Button>
            </div>
          )}

          {/* </DialogClose> */}
        </div>
      </div>
      <div className="flex flex-col justify-between w-full max-h-[80vh] no-scrollbar h-full">
        <div className="flex flex-col justify-between w-full h-full">
          <ChatList
            messages={messageState}
            setStep={setStep}
            setShowForm={setShowForm}
            sendMessage={sendMessage}
            isMobile={isMobile}
            handleQuestionClick={handleQuestionClick}
            loading={loading}
            setLoading={setLoading}
            showForm={showForm}
            removeForm={removeForm}
            sendAfterForm={sendAfterForm}
            onOpenChange={props.onOpenChange}
            submittedTime={submittedTime}
          />
        </div>
      </div>
    </section>
  );
};

export default SmartForm;
