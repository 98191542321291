import { title } from "process";
import { v4 as uuidv4 } from "uuid";

export const trialReply = [
  {
    id: uuidv4(),
    qid: 2,
    reply: [
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        path: "Trial products",
        message: "Okay...",
      },
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        path: "Trial products",
        message: "What is your role?",
      },
    ],
    options: [
      {
        id: uuidv4(),
        qid: 4,
        isUser: true,
        isSelected: false,
        path: "Trial products",
        message: "I’m a facility manager or similar",
        reply: [],
        options: [
          {
            id: uuidv4(),
            qid: 5,
            isUser: true,
            isSelected: false,
            path: "Trial products",
            message: "0-10",
            reply: [
              {
                id: uuidv4(),
                isUser: false,
                isSelected: false,
                path: "Trial products",
                message: "One last thing...",
              },
              {
                id: uuidv4(),
                isUser: false,
                isSelected: false,
                path: "Trial products",
                message: "Can I grab your details?",
              },
            ],
            options: [
              {
                id: uuidv4(),
                qid: 6,
                isUser: true,
                isSelected: false,
                path: "Trial products",
                message: "showForm",
                reply: [],
                options: [],
              },
            ],
          },
          {
            id: uuidv4(),
            qid: 5,
            isUser: true,
            isSelected: false,
            path: "Trial products",
            message: "10-50",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 5,
            isUser: true,
            isSelected: false,
            path: "Trial products",
            message: "50-100",
            reply: [],
            options: [],
          },
        ],
      },
      {
        id: uuidv4(),
        qid: 4,
        isUser: true,
        isSelected: false,
        path: "Trial products",
        message: "I’m a cleaning contractor",
        reply: [],
        options: [],
      },
      {
        id: uuidv4(),
        qid: 4,
        isUser: true,
        isSelected: false,
        path: "Trial products",
        message: "Other",
        reply: [],
        options: [],
      },
    ],
  },
];
export const inquiryReply = [
  {
    id: uuidv4(),
    qid: 2,
    reply: [
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        message: "Okay...",
      },
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        message: "What is your role?",
      },
    ],
    options: [
      {
        id: uuidv4(),
        qid: 3,
        isUser: true,
        isSelected: false,
        path: "General Enquiry",
        message: "I’m a facility manager or similar",
        reply: [
          {
            id: uuidv4(),
            isUser: false,
            isSelected: false,
            path: "General Enquiry",
            message: "Great!",
          },
          {
            id: uuidv4(),
            isUser: false,
            isSelected: false,
            path: "General Enquiry",
            message: "Just fill out the form below and I’ll get back to you.",
          },
        ],
        options: [
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "General Enquiry",
            message: "showForm",
            reply: [],
            options: [],
          },
        ],
      },
      {
        id: uuidv4(),
        qid: 3,
        isUser: true,
        isSelected: false,
        path: "General Enquiry",
        message: "I’m a cleaning contractor",
        reply: [],
        options: [],
      },
      {
        id: uuidv4(),
        qid: 3,
        isUser: true,
        isSelected: false,
        path: "General Enquiry",
        message: "Other",
        reply: [],
        options: [],
      },
    ],
  },
];
export const distributorReply = [
  {
    id: uuidv4(),
    qid: 2,
    reply: [
      {
        id: uuidv4(),
        isUser: false,
        isSelected: false,
        message: "Do you want to distribute or order Enviroplus products?",
      },
    ],
    options: [
      {
        id: uuidv4(),
        qid: 3,
        isUser: true,
        isSelected: false,
        path: "Distributor",
        message: "I want to distribute",
        reply: [
          {
            id: uuidv4(),
            isUser: false,
            isSelected: false,
            path: "Distributor",
            message: "Okay...",
          },
          {
            id: uuidv4(),
            isUser: false,
            isSelected: false,
            path: "Distributor",
            message: "What products are you interested in?",
          },
        ],
        options: [
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Floor Care",
            reply: [
              {
                id: uuidv4(),
                isUser: false,
                isSelected: false,
                path: "Distributor",
                message: "Great!",
              },
              {
                id: uuidv4(),
                isUser: false,
                isSelected: false,
                path: "Distributor",
                message:
                  "Just fill out the form below and I’ll get back to you.",
              },
            ],
            options: [
              {
                id: uuidv4(),
                qid: 5,
                isUser: true,
                isSelected: false,
                path: "Distributor",
                message: "showForm",
                reply: [],
                options: [],
              },
            ],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Washroom",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Industrial",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Personal Care",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Waste",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Laundry",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "Tools",
            reply: [],
            options: [],
          },
          {
            id: uuidv4(),
            qid: 4,
            isUser: true,
            isSelected: false,
            path: "Distributor",
            message: "All of the above",
            reply: [],
            options: [],
          },
        ],
      },
      {
        id: uuidv4(),
        qid: 3,
        isUser: true,
        isSelected: false,
        path: "Distributor",
        message: "I want to order",
        reply: [],
        options: [],
      },
    ],
  },
];

export const userData = [
  {
    id: 1,
    messages: [
      // {
      //   id: 1,
      //   avatar: "/User1.png",
      //   name: "Jane Doe",
      //   message: "Hey, Jakob",
      // },
      //   {
      //     id: 2,
      //     avatar: "/LoggedInUser.jpg",
      //     name: "Jakob Hoeg",
      //     message: "Hey!",
      //   },
      //   {
      //     id: 3,
      //     avatar: "/User1.png",
      //     name: "Jane Doe",
      //     message: "How are you?",
      //   },
      //   {
      //     id: 4,
      //     avatar: "/LoggedInUser.jpg",
      //     name: "Jakob Hoeg",
      //     message: "I am good, you?",
      //   },
      //   {
      //     id: 5,
      //     avatar: "/User1.png",
      //     name: "Jane Doe",
      //     message: "I am good too!",
      //   },
      //   {
      //     id: 6,
      //     avatar: "/LoggedInUser.jpg",
      //     name: "Jakob Hoeg",
      //     message: "That is good to hear!",
      //   },
      //   {
      //     id: 7,
      //     avatar: "/User1.png",
      //     name: "Jane Doe",
      //     message: "How has your day been so far?",
      //   },
      //   {
      //     id: 8,
      //     avatar: "/LoggedInUser.jpg",
      //     name: "Jakob Hoeg",
      //     message:
      //       "It has been good. I went for a run this morning and then had a nice breakfast. How about you?",
      //   },
      //   {
      //     id: 9,
      //     avatar: "/User1.png",
      //     name: "Jane Doe",
      //     message: "I had a relaxing day. Just catching up on some reading.",
      //   },
    ],
  },
];

export type UserData = (typeof userData)[number];

export interface Message {
  id: number;
  questionId: string;
  isUser: boolean;
  isSelected?: boolean;
  message: string;
  time?: string;
  replyTime?: string;
}

export interface User {
  id: number;
  avatar: string;
  messages: Message[];
  name: string;
}

// const questionList = [
//   {
//     id: "q1",
//     question: "I want to trial your products",
//     reply: ["Great! ", "What area of cleaning do you need help with?"],
//     options: [
//       {
//         id: "q1.a",
//         question: "Floor care",
//         reply: ["Okay!", "What is your role?"],
//         options: [
//           {
//             id: "q1.a.b",
//             question: "Floor care",
//             reply: ["Great!", "How many people are in your team?"],
//             options:[
//               {
//                 id: "q1.a.b.c",
//                 question: "Floor care",
//                 reply: ["Great!", "How many people are in your team?"],
//               }
//             ]
//           },
//           {
//             id: "q1.a.b",
//             question: "Washroom care",

//           }
//         ]
//       },
//       {
//         id: "q1.a",
//         question: "Washroom care",
//         reply: ["Okay!", "What is your role?"],
//       },
//       {
//         id: "q1.a",
//         question: "industrial",
//         reply: ["Okay!", "What is your role?"],
//       },
//     ]
//   },
//   {
//     id: "q1",
//     question: "I have a general enquiry",
//   },
//   {
//     id: "q1",
//     question: "I’m looking for/am a distributor",
//   }
// ]

export const floatingData = [
  {
    id: 1,
    heading: "Learn how I can help you...",
    menuTitle: "Find out why I should make the switch",
    parentMenu: "main",
    submenu: [
      {
        id: 1,
        heading: "Clean greener",
        menuTitle: "Clean greener",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/environmental-impact",
        },
      },
      {
        id: 2,
        heading: "Clean healthier",
        menuTitle: "Clean healthier",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/healthy-workplaces",
        },
      },
      {
        id: 3,
        heading: "Clean better",
        menuTitle: "Clean better",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/effective-cleaning",
        },
      },
      {
        id: 4,
        heading: "Reach ESG targets",
        menuTitle: "Reach ESG targets",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/meet-your-esg-goals",
        },
      },
    ],
  },
  {
    id: 2,
    heading: "What cleaning interests you?",
    menuTitle: "Discover greener cleaning products",
    parentMenu: "main",
    submenu: [
      {
        id: 1,
        heading: "Bins and Liners",
        menuTitle: "Bins and Liners",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/bin-and-liners",
        },
      },
      {
        id: 2,
        heading: "Floor & Hard Surface",
        menuTitle: "Floor & Hard Surface",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/hard-surface-cleaning",
        },
      },
      {
        id: 3,
        heading: "Heavy Duty",
        menuTitle: "Heavy Duty",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/heavy-duty-cleaning",
        },
      },
      {
        id: 4,
        heading: "Kitchen",
        menuTitle: "Kitchen",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/kitchen-areas",
        },
      },
      {
        id: 5,
        heading: "Specialty",
        menuTitle: "Specialty",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/specialty-cleaners",
        },
      },
      {
        id: 6,
        heading: "Toilet Paper",
        menuTitle: "Toilet Paper",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/toilet-paper",
        },
      },
      {
        id: 7,
        heading: "Washroom",
        menuTitle: "Washroom",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/washroom-cleaning",
        },
      },
      {
        id: 8,
        heading: "X-Range",
        menuTitle: "X-Range",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/products/concentrated-cleaning",
        },
      },
    ],
  },
  {
    id: 3,
    heading: "What do you want to learn about?",
    menuTitle: "Access helpful cleaning guides ",
    parentMenu: "main",
    submenu: [
      {
        id: 1,
        heading: "The future of cleaning: Microbe-based technology",
        menuTitle: "The future of cleaning: Microbe-based technology",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/learn/the-future-of-cleaning-microbe-based-technology",
        },
      },
      {
        id: 2,
        heading:
          "Minimising the impact of commercial cleaning on the environment",
        menuTitle:
          "Minimising the impact of commercial cleaning on the environment",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/learn/minimising-the-impact-of-commercial-cleaning-on-the-environment",
        },
      },
      {
        id: 3,
        heading: "Cleaning Guide: What are plant-based surfactants?",
        menuTitle: "Cleaning Guide: What are plant-based surfactants?",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/learn/cleaning-guide-what-are-plant-based-surfactants",
        },
      },
      {
        id: 4,
        heading:
          "How to properly dispose of cleaning product containers such as recycling plastic bottles or properly disposing of hazardous materials",
        menuTitle:
          "How to properly dispose of cleaning product containers such as recycling plastic bottles or properly disposing of hazardous materials",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/learn/how-to-properly-dispose-of-cleaning-product-containers-such-as-recycling-plastic-bottles-or",
        },
      },
      {
        id: 5,
        heading: "5 Facility Management Trends to Watch",
        menuTitle: "5 Facility Management Trends to Watch",
        content:
          "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
        button: {
          title: "Find out more",
          link: "/learn/5-facility-management-trends-to-watch",
        },
      },
      // {
      //   id: 1,
      //   heading: "Topic A",
      //   menuTitle: "Topic A",
      //   submenu: [
      //     {
      //       id: 1,
      //       heading: "Subtopic A",
      //       menuTitle: "Subtopic A",
      //       content:
      //         "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
      //       button: {
      //         title: "Find out more",
      //         link: "/products",
      //       },
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   heading: "Topic B",
      //   menuTitle: "Topic B",
      //   submenu: [
      //     {
      //       id: 1,
      //       heading: "Subtopic B",
      //       menuTitle: "Subtopic B",
      //       content:
      //         "We’ve formulated high-performing cleaning products that are easier on the environment and healthier at work. Cleaning greener provides a host of benefits for your business, people and the environment",
      //       button: {
      //         title: "Find out more",
      //         link: "/products",
      //       },
      //     },
      //   ],
      // },
    ],
  },
];
